<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.30
	Description:新闻页,用于展示
	History: 暂无
-->
<template>
  <div>
    <div>
      <swiper :picList="picList"></swiper>
      <news-kind></news-kind>
      <footer-page></footer-page>
    </div>
  </div>
</template>

<script>
import swiper from "@/components/common/swiper/Swiper.vue";
import footerPage from "@/components/common/footer/footerPage.vue";
import NewsKind from "@/components/content/news/NewsKind.vue";

export default {
  name: "News",
  data() {
    return {
      picList: [],
    };
  },
  components: {
    swiper,
    footerPage,
    NewsKind,
  },
  created() {
    //document.title = this.project_title + "-新闻动态";
    this.$store.commit("updateIsActiveIndex", 2);
    let requestParams = { Status: 3, BelongAreaID: "1" };
    //请求轮播图接口
    this.$axios("API/Get_Banner", requestParams)
      .then((res) => {
        this.picList = res.data;
      })
      .catch((err) => {
        console.log(err);
      });

    /* new Promise((resolve) => {
			
			getBanner(requestParams).then((res) => {
				resolve(res)
			})
		}).then(res => {
			this.picList = res.data.data
		}).catch(err => {
			console.log(err)
		}) */
  },
};
</script>

<style>
</style>
