<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.21
	Description:新闻页之新闻中心模块
	History: 暂无
-->
<template>
  <div id="news_content">
    <div class="news_content_in">
      <h2>新闻</h2>
      <div class="new_menu">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item
            v-for="(item, index) in newsTabList"
            :index="index.toString()"
            :key="index"
            >{{ item.DictName }}</el-menu-item
          >
        </el-menu>
      </div>

      <div class="news_list" v-if="total > 0">
        <div
          class="news_single_info"
          v-for="(item, index) in newsList"
          :key="index"
          @click="toNewsDetail(index)"
        >
          <div
            :class="
              item.Typesetting == 1
                ? 'news_detail_img'
                : item.Typesetting == 2
                ? 'news_detail_img_small'
                : 'news_detail_img_low'
            "
          >
            <div class="news_detail_img_in">
              <img
                v-for="(item_in, index_in) in newsList[index].CoverImgUrl.slice(0,1)"
                :key="index_in"
                :src="imgUrlFront + item_in"
                @error="defImg($event, index)"
                alt="找不到图片"
              />
            </div>
          </div>
          <div class="news_detail_info">
            <p class="news_detail_info_data">
              {{ item.PublishTimeDisplay }}
            </p>
            <p class="news_detail_info_kind">
              <i v-for="(itemin, index) in newsList[index].Tags" :key="index">
                {{ itemin }}
              </i>
            </p>
            <h6>{{ item.ArticleTitle }}</h6>
            <span class="readmore">阅读更多</span>
          </div>
        </div>
      </div>
      <div class="paging paging_onpc" v-if="total > 0">
        <el-pagination
          background
          layout="prev,pager,next"
          @prev-click="prevclick"
          @next-click="nextclick"
          @current-change="currentclick"
          :current-page="currentPage"
          :total="total"
          :page-size="pageSize"
        >
        </el-pagination>
      </div>
      <div class="paging paging_phone" v-if="total > 0">
        <el-pagination
          small
          background
          layout="prev,pager,next"
          @prev-click="prevclick"
          @next-click="nextclick"
          @current-change="currentclick"
          :current-page="currentPage"
          :total="total"
          :page-size="pageSize"
        >
        </el-pagination>
      </div>
      <div class="nodata" v-if="total == 0">暂无更多数据</div>
    </div>
  </div>
</template>

<script>
import { setSession } from "@/utils/utils.js";
export default {
  name: "NewsKind",
  data() {
    return {
      newsTabList: [{ DictName: "全部新闻", DictID: "" }],
      imgUrlFront: "", //图片前缀
      activeIndex: "0",
      newsList: [], //
      total: 0, //总数
      pageSize: 9, //一页的数量
      currentPage: 1, //当前页码
    };
  },
  created() {
    // 请求新闻类型和
    this.$axios("/API/GetDict", {
      Version: this.Version,
      DictTypeID: "XT_WZLX",
    }).then((res) => {
      res.data.forEach((i) => {
        this.newsTabList.push(i);
      });
    });
    this.imgUrlFront = window.sessionStorage.getItem("apiImgUrl");
    this.$axios("API/Get_Article", {
      Version: "",
      Status: 3,
      IsShowIndex: "0",
      ArticleTypeID: "",
      PageIndex: 1,
      PageSize: this.pageSize,
    }).then((res) => {
      this.total = res.count;
      res.data.forEach((item) => {
        item.CoverImgUrl = decodeURIComponent(item.CoverImgUrl).split(",",2);
        item.Tags = item.Tags.split(",");
        item.ArticleTitle = decodeURIComponent(item.ArticleTitle);
        this.newsList.push(item);
      });
    });
  },
  methods: {
    handleSelect(key, keyPath) {
      let id = parseInt(key);
      this.activeIndex = key
      this.currentPage = 1; //页码返回1
      //获取对应文章
      this.getArticle(this.currentPage, this.newsTabList[id].DictID);
    },
    //跳转至新闻详情
    toNewsDetail(index) {
	    let flnkid = this.newsList[index].FLnkID
      this.$router.push({
        name: "newsdetail",
        query: {
          newsflnkid: flnkid,
        },
      });
    },
    //上一页
    prevclick() {
      this.currentPage -= 1;
      this.getArticle(
        this.currentPage,
        this.newsTabList[this.activeIndex].DictID
      );
    },
    //下一页
    nextclick() {
      this.currentPage += 1;
      this.getArticle(
        this.currentPage,
        this.newsTabList[this.activeIndex].DictID
      );
    },
    //页码改变，当前页
    currentclick(e) {
      let that = this;
      that.currentPage = e;
      that.getArticle(
        this.currentPage,
        this.newsTabList[this.activeIndex].DictID
      );
    },
     defImg(item, index) {
      let img = item.srcElement;
    
      switch (this.newsList[index].ArticleTypeID) {
        case "1":
          img.src = require("../../../assets/images/news/zxzc.png");
          break;
        case "2":
          img.src = require("../../../assets/images/news/hyxw.png");
          break;
        case "3":
          img.src = require("../../../assets/images/news/qyzz.png");
          break;
        case "4":
          img.src = require("../../../assets/images/news/jjjl.png");
          break;
        default:
          img.src = require("../../../assets/images/news/zscq.png");
          break;
      }
      img.onerror = null; //防止闪图
    },

    //获取文章
    getArticle(currentPage, ArticleTypeID) {
      this.$axios("API/Get_Article", {
        Version: "",
        Status: 3,
        IsShowIndex: "0",
        ArticleTypeID: ArticleTypeID,
        PageIndex: currentPage,
        PageSize: this.pageSize,
      }).then((res) => {
        // console.log(res)
        this.total = res.count;
        this.newsList = [];
        res.data.forEach((item) => {
          item.CoverImgUrl =decodeURIComponent(item.CoverImgUrl).split(",", 2);
          item.Tags = item.Tags.split(",");
          item.ArticleTitle = decodeURIComponent(item.ArticleTitle);
          this.newsList.push(item);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @media( min-width: 1201px){
    @import '@/components/content/news/NewsKind.scss';
  }
  @media( max-width: 1200px){
    @import '@/components/content/news/NewsKind_phone.scss';

  }
</style>
